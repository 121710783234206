import Image from 'generic/Image';
import MarkdownText from 'generic/MarkdownText';
import BulletList from 'generic/BulletList';
import StyledDiv from 'generic/StyledDiv';

import styles from './styles.module.sass';

export default function TwoColumnCta({
  heading,
  description,
  leftImage,
  cta,
  ctaCopy,
  bullets,
  background_color,
}) {
  return (
    <StyledDiv className={styles.twoColumnCta} backgroundColor={background_color}>
      <div className={styles.container}>
        <div className={styles.left}>
          <Image
            src={leftImage}
            alt={leftImage.title || 'left-image'}
            height={360}
            width={360}
            className={styles.thumbnail}
          />
        </div>

        <div className={styles.right}>
          <Image
            src={leftImage}
            alt={leftImage.title || 'left-image'}
            height={360}
            width={360}
            className={styles.thumbnail}
          />
          <div>
            <div className={styles.heading}>{heading}</div>
            <MarkdownText className={styles.description} text={description} />
            {/* @ts-ignore */}
            {bullets?.bullets && <BulletList bullets={bullets.bullets} />}
          </div>
          <a href={cta} className={styles.cta}>
            {ctaCopy || 'Click Here'}
          </a>
        </div>
      </div>
    </StyledDiv>
  );
}
