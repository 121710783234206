const queryTemplate = {
  '... on ProductCard': [
    'id',
    'title',
    'description',
    'buttonText',
    { image: ['...FilePartsWithMetadata'] },
    'imageAlt',
    'titleColor',
    'textColor',
    'buttonColor',
    'buttonTextColor',
    'buttonBorderColor',
    'buttonOnHoverColor',
    'buttonOnHoverTextColor',
    'buttonOnHoverBorderColor',
    { textFont: ['family'] },
    { headingFont: ['family'] },
  ],
};

export default queryTemplate;
