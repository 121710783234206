import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useScroll } from 'ahooks';

import { useTextFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

import Image from './Image';
import styles from './ClaimScroller.module.css';

const propTypes = {
  claims: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        icon: PropTypes.object,
        text: PropTypes.string,
      }),
    })
  ),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  textFont: PropTypes.object,
};

const defaultProps = {
  claims: null,
  backgroundColor: null,
  textColor: null,
  textFont: null,
};

function ClaimScroller({ claims, backgroundColor, textColor, textFont }) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  const [scrollTarget, setScrollTarget] = useState(null);
  const scroll = useScroll(scrollTarget);
  const scrollPosition = scroll?.top;

  useEffect(() => {
    setScrollTarget(document);
  }, []);

  const claimItems = [];
  for (let i = 0; i < 5; i++) {
    claimItems.push(...claims);
  }

  const horizontalScroll = (scrollPosition / 200) % (claimItems.length * 100);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--primary-color)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      <div
        className={styles.wrapper}
        style={{
          transform: `translate3d(-${horizontalScroll}%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
        }}
      >
        {!!claimItems &&
          claimItems.map(({ item }, i) => {
            return (
              <div key={`${item.text}-${i}`} className={styles.item_wrapper}>
                {item.icon && <Image src={item.icon} alt="claim" />}
                <p>{item.text}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
}

ClaimScroller.propTypes = propTypes;
ClaimScroller.defaultProps = defaultProps;

export default ClaimScroller;
