import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ContentModule from './ContentModule';
import ReasonsWhy from './ArticleComponents/components/ReasonsWhy';
import Timeline from './Timeline';
import Reviews from './ArticleComponents/components/Reviews';
import FeaturedIn from './ArticleComponents/components/FeaturedIn';
import TwoColumnCta from './ArticleComponents/components/TwoColumnCta';
import ArticleTwoColumn from './ArticleTwoColumn';
import CtaButton from './CtaButton';
import SubscriptionForm from './SubscriptionForm';
import Article from './Article';
import ProductCard from './ProductCard';
import ClaimScroller from './ClaimScroller';

// Map Directus sections to section components
const sectionComponents = {
  ArticleTwoColumn,
  ContentModule,
  ReasonsWhy,
  Timeline,
  Reviews,
  FeaturedIn,
  TwoColumnCta,
  CtaButton,
  SubscriptionForm,
  Article,
  ProductCard,
  ClaimScroller,
};

const sectionPropTypes = {
  sectionData: PropTypes.object.isRequired,
};

const sectionDefaultProps = {};

// Display a section individually
const Section = ({ sectionData }) => {
  const SectionComponent = sectionComponents[sectionData.collection];
  if (!SectionComponent) return null;

  // Display the section
  return <SectionComponent {...sectionData.item} />;
};

Section.propTypes = sectionPropTypes;
Section.defaultProps = sectionDefaultProps;

const sectionsPropTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const sectionsDefaultProps = {};

// Display the list of sections
const SectionsTheme2 = ({ sections }) => {
  const sectionsList = useMemo(() => {
    return sections;
  }, [sections]);

  return (
    <>
      {/* Show the actual sections */}
      {sectionsList &&
        sectionsList.map(section => (
          <Section sectionData={section} key={`${section.collection}${section.id}`} />
        ))}
    </>
  );
};

SectionsTheme2.propTypes = sectionsPropTypes;
SectionsTheme2.defaultProps = sectionsDefaultProps;

export default SectionsTheme2;
