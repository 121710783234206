import * as yup from 'yup';
import { ReasonWhy, reasonWhyValidation } from './components/ReasonWhy';

import styles from './styles.module.sass';

const defaultProps = {
  reasons: [],
};

const reasonsWhyValidation = yup.array().of(
  yup.object().shape({
    id: yup.string().required(),
    item: reasonWhyValidation,
  })
);

export type ReasonsWhyProps = yup.InferType<typeof reasonsWhyValidation>;

export default function ReasonsWhy({ reasons }: { reasons: ReasonsWhyProps }) {
  return (
    <div className={styles.column}>
      {reasons.map((reason, index) => (
        <ReasonWhy key={reason.id} order={index + 1} {...reason.item} />
      ))}
    </div>
  );
}

ReasonsWhy.defaultProps = defaultProps;
