import PropTypes from 'prop-types';
import Link from 'next/link';

import { formatSlug } from 'utils/article-helpers';
import formatDate from 'utils/format-article-date';
import { buildCssVar } from 'utils/style-override';
import { useFunnelData } from 'utils/funnel-data-context';
import { useGetPathWithQueryParams } from 'utils/article-link';

import HomePageSections from './HomePageSections';
import GlobalStyle from './GlobalStyle';
import Image from './Image';
import NavigationLink from './NavigationLink';

import styles from './Article.module.css';

const propTypes = {
  pageData: PropTypes.shape({
    id: PropTypes.string,
    logo: PropTypes.object,
    category: PropTypes.shape({ name: PropTypes.string }),
    tag: PropTypes.shape({ name: PropTypes.string }),
    publishDate: PropTypes.string,
    authorName: PropTypes.string,
    mainHeading: PropTypes.string,
    coverImage: PropTypes.object,
    coverImageAlt: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object),
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    headingColorDark: PropTypes.string,
    headingColorLight: PropTypes.string,
    textColorDark: PropTypes.string,
    textColorLight: PropTypes.string,
    tagBackgroundColor: PropTypes.string,
    tagTextColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontSize: PropTypes.string,
    headingFont: PropTypes.object,
    textFont: PropTypes.object,
  }).isRequired,
};

const defaultProps = {};

export default function Article({ pageData }) {
  const {
    logo,
    publishDate,
    authorName,
    mainHeading,
    coverImage,
    coverImageAlt,
    sections,
    tag,
    tagBackgroundColor,
    tagTextColor,
    ...globalStyles
  } = pageData;

  const { fonts, article, funnelId } = useFunnelData();

  const homePath = useGetPathWithQueryParams('/home');

  return (
    <div className={`root ${styles.container}`}>
      <GlobalStyle fonts={fonts} {...globalStyles} />

      <style jsx>{`
        .root {
          ${buildCssVar(
            '--tag-background-color',
            tagBackgroundColor,
            'var(--background-color-grey)'
          )}
          ${buildCssVar('--tag-text-color', tagTextColor, 'var(--text-color-light)')}
        }
      `}</style>
      <div className={styles.header}>
        <div className={styles.logo_wrapper}>
          <Link className={styles.logo_btn} href={homePath}>
            <Image src={logo} alt={'Logo'} />
          </Link>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.breadcrumb}>
          <Link href={homePath}>Home</Link>
          <span>/</span>
          <NavigationLink slug={article?.category?.name} />
        </div>
        <div className={styles.tag_wrapper}>
          <Link href={`/category/articles/${formatSlug(tag.name)}?fid=${funnelId}`} passHref>
            <p className={styles.tag}>{tag.name}</p>
          </Link>
          {publishDate && (
            <>
              <p>&#x2022;</p>
              <p>{formatDate(publishDate)}</p>
            </>
          )}
        </div>
        <h1 className={styles.main_heading}>{mainHeading}</h1>
        <p className={styles.author}>
          {authorName && <span>Words By: </span>}
          <span>{authorName}</span>
        </p>
        {coverImage && (
          <div className={styles.img_wrapper}>
            <Image src={coverImage} alt={coverImageAlt} sizes="800px" priority />
          </div>
        )}
      </div>

      <HomePageSections sections={sections} />
    </div>
  );
}

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;
