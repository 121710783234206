import * as yup from 'yup';

import RatingStars from 'generic/RatingStars';
import MarkdownText from 'generic/MarkdownText';
import Image from 'generic/Image';

import styles from './styles.module.sass';

const reviewValidation = yup.object().shape({
  id: yup.string().required(),
  rating: yup.number().required(),
  title: yup.string().required(),
  review_body: yup.string().required(),
  buyer_name: yup.string().required(),
  buyer_image: yup.object().shape({
    filename_disk: yup.string().required(),
    filesize: yup.string().required(),
    id: yup.string().required(),
    title: yup.string().required(),
    type: yup.string().required(),
  }),
});

export type ReviewProps = yup.InferType<typeof reviewValidation>;

export function Review({ review }: { review: ReviewProps }) {
  // Run review through the validation schema
  const data = reviewValidation.cast(review);

  const { rating, title, review_body, buyer_name, buyer_image } = data;

  return (

    <div className={styles.gridItem}>
      <div className={styles.content}>
        <div className={styles.top}>
          <RatingStars
            className={styles.stars}
            rating={rating}
            starColor="#ffcd35"
            starEmptyColor="#b7b7b7"
          />
          <div className={styles.title}>{title}</div>
          <MarkdownText text={review_body} className={styles.reviewBody} />
        </div>

        <div className={styles.buyer}>
          <div className={styles.buyerBio}>
            <div className={styles.buyerName}>{buyer_name}</div>
            <div className={styles.verified}>Verified Buyer</div>
          </div>
          <Image
            src={buyer_image}
            alt="Test"
            height={60}
            width={60}
            className={styles.buyerImage}
          />
        </div>
      </div>
    </div>
  );
}
