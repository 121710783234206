const queryTemplate = {
  '... on FeaturedIn': [
    'title',
    {
      images: [
        {
          item: [
            {
              '... on FeaturedInImage': [{ image: ['...FilePartsWithMetadata'] }, 'imageAlt'],
            },
          ],
        },
      ],
    },
    'headingColor',
    'backgroundColor',
    { headingFont: ['family'] },
  ],
};

export default queryTemplate;
