import PropTypes from 'prop-types';
import { buildCssVar } from 'utils/style-override';
import ArticleComponent from './ArticleComponent';
import ProductCard from './ProductCard';
import styles from './ArticleTwoColumn.module.css';

const propTypes = {
  title: PropTypes.string,
  leftSections: PropTypes.arrayOf(PropTypes.object),
  rightSections: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
};

const defaultProps = {
  title: null,
  leftSections: null,
  rightSections: null,
  backgroundColor: null,
};

export default function ArticleTwoColumn({
  title,
  leftSections,
  leftArticles,
  featuredProduct,
  rightArticles,
  backgroundColor,
}) {
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
        }
      `}</style>
      {title && <h1 className={styles.title}>{title}</h1>}
      <div className={styles.articles_wrapper}>
        {!!leftSections.length && (
          <div className={styles.row_articles}>
            {leftArticles.map(({ item }, i) => {
              return (
                item && (
                  <ArticleComponent
                    key={item.id}
                    {...item}
                    layout="layout1"
                    isSingleChild={i % 2 === 0 && leftSections.length - 1 === i}
                    // imagePriority={i === 0}
                    //imageLoading={i === 0 ? 'eager' : 'lazy'}
                    imageFill={true}
                    imageSizes="(min-width: 981px) 330px, 980px"
                  />
                )
              );
            })}
          </div>
        )}
        <div>
          {!!rightArticles.length && (
            <div className={`${styles.right_column_items} ${styles.right_articles}`}>
              {rightArticles.map(({ item }) => {
                return (
                  item && (
                    <ArticleComponent
                      key={item.id}
                      {...item}
                      layout="layout2"
                      imageHeight={150}
                      imageWidth={150}
                      imageSizes="150px"
                    />
                  )
                );
              })}
            </div>
          )}
          {!!featuredProduct.length && (
            <>
              <div className={styles.featured_title_wrapper}>
                <h1 className={styles.featured_title}>Featured Products</h1>
                <div className={styles.divider}></div>
              </div>
              <div className={`${styles.right_column_items} ${styles.featured_products}`}>
                {featuredProduct.map(({ item }) => {
                  return (
                    item && (
                      <ProductCard
                        key={item.id}
                        {...item}
                        layout="layout2"
                        imageHeight={150}
                        imageWidth={150}
                        imageSizes="150px"
                      />
                    )
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

ArticleTwoColumn.propTypes = propTypes;
ArticleTwoColumn.defaultProps = defaultProps;
