/*
Had to remove this from the ... on Bullet fragment due to "Max relational depth exceeded" error from Directus on the homepage 
icon {
  ...FilePartsWithMetadata
}
*/

const twoColumnCtaQueryTemplate = `
  ... on TwoColumnCta {
      id
      sort
      heading
      description
      cta
      ctaCopy
      leftImage {
        ...FilePartsWithMetadata
      }
      background_color
      bullets {
        id
        text
        bullets {
          item {
            ... on Bullet {
              text
            }
          }
        }
      }
  }
`;

export default twoColumnCtaQueryTemplate;
