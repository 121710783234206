import contentModuleQueryTemplate from './contentModuleQueryTemplate';
import productCardQueryTemplate from './productCardQueryTemplate';
import reasonsWhyQueryTemplate from './reasonsWhyQueryTemplate';
import reviewsQueryTemplate from './reviewsQueryTemplate';
import featuredInQueryTemplate from './featuredInQueryTemplate';
import twoColumnCtaQueryTemplate from './twoColumnCtaQueryTemplate';
import timelinesQueryTemplate from './timelinesQueryTemplate';

const queryTemplate = {
  '... on ArticlePage': [
    'id',
    'name',
    'title',
    { logo: ['...FilePartsWithMetadata'] },
    { favicon: ['...FilePartsWithMetadata'] },
    { category: ['name'] },
    { tag: ['name'] },
    'publishDate',
    'authorName',
    'mainHeading',
    { coverImage: ['...FilePartsWithMetadata'] },
    'coverImageAlt',
    {
      sections: [
        'id',
        'collection',
        {
          item: [
            contentModuleQueryTemplate,
            productCardQueryTemplate,
            reasonsWhyQueryTemplate,
            reviewsQueryTemplate,
            featuredInQueryTemplate,
            twoColumnCtaQueryTemplate,
            timelinesQueryTemplate,
          ],
        },
      ],
    },
    'primaryColor',
    'secondaryColor',
    'headingColorDark',
    'headingColorLight',
    'textColorDark',
    'textColorLight',
    'tagBackgroundColor',
    'tagTextColor',
    'backgroundColor',
    'fontSize',
    { headingFont: ['family'] },
    { textFont: ['family'] },
  ],
};

export default queryTemplate;
