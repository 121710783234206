const reviewQueryTemplate = {
  '... on Review': [
    'id',
    'rating',
    'title',
    'review_body',
    'buyer_name',
    { buyer_image: ['...FilePartsWithMetadata'] },
  ],
};

const reviewsQueryTemplate = {
  '... on Reviews': [
    'id',
    'sort',
    'heading',
    'subheading',
    {
      reviews: ['id', { item: [reviewQueryTemplate] }],
    },
  ],
};

export default reviewsQueryTemplate;
