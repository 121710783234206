import Image from 'generic/Image';
import { useHeadingFontOverrides } from 'utils/font-override';
import { buildCssVar } from 'utils/style-override';

import styles from './styles.module.css';

const defaultProps = {
  title: null,
  headingColor: null,
  backgroundColor: null,
  headingFont: null,
};

export default function FeaturedIn({ title, images, headingColor, backgroundColor, headingFont }) {
  const headingFontOverrides = useHeadingFontOverrides(headingFont?.family);
  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--heading-color', headingColor, 'var(--heading-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${headingFontOverrides ?? ''}
        }
      `}</style>

      {title && <div className={styles.heading}>{title}</div>}
      <div className={styles.featured_in}>
        {images.map(({ item }, index) => {
          return (
            <div className={styles.image_wrapper} key={index}>
              <Image
                src={item.image}
                alt={item.imageAlt}
                width={150}
                height={150}
                placeholder="blur"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

FeaturedIn.defaultProps = defaultProps;
