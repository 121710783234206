const reasonQueryTemplate = {
  '... on ReasonWhy': ['id', 'title', { thumbnail: ['...FilePartsWithMetadata'] }, 'body'],
};

const reasonsQueryTemplate = {
  '... on ReasonsWhy': [
    'id',
    'sort',
    {
      reasons: ['id', { item: [reasonQueryTemplate] }],
    },
  ],
};

export default reasonsQueryTemplate;
