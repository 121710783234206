import { Review } from './components/Review';

import styles from './styles.module.sass';

const defaultProps = {
  reviews: [],
};

export default function Reviews({ heading, subheading, reviews }) {
  return (
    <div className={styles.reviews}>
      <div>
        {heading && <div className={styles.heading}>{heading}</div>}
        {subheading && <div className={styles.subheading}>{subheading}</div>}
      </div>
      <div className={styles.grid}>
        {reviews.map(review => (
          <Review key={review.id} review={review.item} />
        ))}
      </div>
    </div>
  );
}

Reviews.defaultProps = defaultProps;
