import * as yup from 'yup';

import MarkdownText from 'generic/MarkdownText';
import Image from 'generic/Image';

import styles from './styles.module.sass';
import useMobileDetect from 'hooks/useMobileDetect';

const defaultProps = {
  title: '',
  body: '',
  thumbnail: {},
};

// TODO: Cast this validation further up the tree at the section level
export const reasonWhyValidation = yup.object().shape({
  id: yup.string().required(),
  title: yup.string().required(),
  body: yup.string().required(),
  order: yup.number().required(),
  thumbnail: yup.object().shape({
    filename_disk: yup.string().required(),
    filesize: yup.string().required(),
    id: yup.string().required(),
    title: yup.string().required(),
    type: yup.string().required(),
  }),
});

// Generate a type based off the validation schema
export type ReasonWhyProps = yup.InferType<typeof reasonWhyValidation>;

export function ReasonWhy({ title, body, thumbnail, order }: ReasonWhyProps) {
  const isMobile = useMobileDetect();

  return isMobile ? (
    <ReasonWhyCardMobile title={title} body={body} thumbnail={thumbnail} order={order} />
  ) : (
    <ReasonWhyCardDesktop title={title} body={body} thumbnail={thumbnail} order={order} />
  );
}

function ReasonWhyCardDesktop({ title, body, thumbnail, order }: ReasonWhyProps) {
  return (
    <div className={styles.row}>
      <Image
        src={thumbnail}
        alt={thumbnail.title || 'reason-why'}
        height={325}
        width={325}
        className={styles.thumbnail}
      />
      <div className={styles.column}>
        <div className={styles.reasonTitle}>
          {order}. {title}
        </div>
        <MarkdownText text={body} />
      </div>
    </div>
  );
}

function ReasonWhyCardMobile({ title, body, thumbnail, order }: ReasonWhyProps) {
  return (
    <div className={styles.row}>
      <div className={styles.column}>
        <div className={styles.reasonTitle}>
          {order}. {title}
        </div>
        <Image
          src={thumbnail}
          alt={thumbnail.title || 'reason-why'}
          height={325}
          width={325}
          className={styles.thumbnail}
        />
        <MarkdownText text={body} />
      </div>
    </div>
  );
}

ReasonWhy.defaultProps = defaultProps;
